import React, {useEffect} from "react";
import classes from "./SoftwareImplementation.module.scss";
import Heading from "./heading/Heading.tsx";
import About from "./about/About.tsx";
import KeyFocus from "./key-focus/KeyFocus.tsx";
import HowWeWork from "./how-we-work/HowWeWork.tsx";
import Why from "./why/Why.tsx";
import WhatWeOffer from "./what-we-offer/WhatWeOffer.tsx";
import Services, {
  EServicesNames,
} from "../../components/services/Services.tsx";
import FormCard from "../IT-consulting/form-card/FormCard.tsx";

const SoftwareImplementation = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
  return (
    <main>
      <Heading />
      <About />
      <KeyFocus />
      <HowWeWork />
      <Why />
      <WhatWeOffer />
      <div className={classes["services-container"]}>
        <Services currentServiceName={EServicesNames.SUPPORT} />
      </div>
      <FormCard />
    </main>
  );
};

export default SoftwareImplementation;
